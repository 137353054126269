import React from 'react'
import LOGO from '@/assets/images/logo-white.webp'
import { Layout } from '../Layout'
import facebookImg from 'assets/svgs/facebook.svg'
import youtubeImg from 'assets/svgs/youtube.svg'
import instagramImg from 'assets/svgs/instagram.svg'
import tiktokImg from 'assets/svgs/tiktok.svg'
import LinkedinImg from 'assets/svgs/linkedin.svg'
import visaImg from 'assets/svgs/footer_visa.svg'
import masterCardImg from 'assets/svgs/footer_mastercard.svg'
import paypalImg from 'assets/svgs/footer_paypal.svg'
import appStoreImg from 'assets/images/app-store.png'
import playStoreImg from 'assets/images/google-play.png'
import {
  FACEBOOK,
  INSTAGRAM,
  YOUTUBE,
  TIKTOK,
  LINKEDIN,
  GOTUURI_BLOG,
} from '@/constants/socialLinks'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { track } from '@/utils/analytics'
import { Path } from '@/constants/path'

const Footer = () => {
  const { t } = useTranslation()
  return (
    <div className=" bg-black h-auto w-full bottom-0 mt-auto">
      <Layout>
        <div className="flex flex-col md:flex-row h-auto md:h-auto w-full bg-black py-16 justify-between md:items-start mt-12 gap-10 text-white ">
          <div className="mt-8">
            <Link to="/">
              <img
                className="h-8 mb-8"
                src={LOGO}
                alt="Logo gotuuri svg"
                width="139.31"
                height="32"
              />
            </Link>
            <div className="flex flex-row">
              <a className="gotuuri-filter" href={FACEBOOK} target="_blank">
                <img
                  className="h-8 p-2 pl-0  md:mb-6"
                  src={facebookImg}
                  alt="Logo Facebook gotuuri svg"
                  width="23.23"
                  height="32"
                />
              </a>
              <a className="gotuuri-filter" href={INSTAGRAM} target="_blank">
                <img
                  className="h-8 p-2"
                  src={instagramImg}
                  alt="Logo Instagram gotuuri svg"
                  width="31.23"
                  height="32"
                />
              </a>
              <a className="gotuuri-filter" href={YOUTUBE} target="_blank">
                <img
                  className="h-8 p-2"
                  src={youtubeImg}
                  alt="Logo Youtube gotuuri svg"
                  width="31.23"
                  height="32"
                />
              </a>
              <a className="gotuuri-filter" href={TIKTOK} target="_blank">
                <img
                  className="h-8 p-2"
                  src={tiktokImg}
                  alt="Logo TikTok gotuuri svg"
                  width="30"
                  height="32"
                />
              </a>
              <a className="gotuuri-filter" href={LINKEDIN} target="_blank">
                <img
                  className="h-8 p-2"
                  src={LinkedinImg}
                  alt="Logo Linkedin gotuuri svg"
                  width="31.23"
                  height="32"
                />
              </a>
            </div>
          </div>

          <div className="flex md:flex-1 justify-between md:justify-evenly mb-3">
            <div className="mx-0">
              <p className="!mb-5 font-semibold">{t('footer.gotuuri')}</p>
              <Link to="/about">
                <p className="mb-2 hover:text-gotuuriCyan">
                  {t('footer.AboutUs')}
                </p>
              </Link>
              <Link
                to={Path.GIFTCARDS}
                onClick={() => {
                  track('Buy Gift Card Click', { screen: 'Footer' })
                }}
              >
                <p className="mb-2 hover:text-gotuuriCyan">
                  {t('footer.giveAnExperience')}
                </p>
              </Link>
              <a
                href={GOTUURI_BLOG}
                target="_blank"
                onClick={() => {
                  track('Blog click')
                }}
              >
                <p className="hover:text-gotuuriCyan">{t('footer.blog')}</p>
              </a>
              <Link to={Path.TUURI_REGISTER}>
                <p className="mb-2 mt-2 hover:text-gotuuriCyan">
                  {t('footer.tuuriRegister')}
                </p>
              </Link>
            </div>
            <div className="mx-0">
              <p className="!mb-5 font-semibold">{t('footer.support')}</p>

              <Link to="/terms-and-conditions">
                <p className="mb-2 hover:text-gotuuriCyan">
                  {t('footer.termsAndConditions')}
                </p>
              </Link>
              <Link to="/privacy-policies">
                <p className="mb-2 hover:text-gotuuriCyan">
                  {t('footer.privacyPolicies')}
                </p>
              </Link>
              <Link to="/faqs">
                <p className="hover:text-gotuuriCyan">{t('footer.faqs')}</p>
              </Link>
            </div>
          </div>

          <div className="flex flex-row gap-12 -ml-2">
            <div className="flex flex-row md:flex-col space-y-4 mb-auto">
              <div className="flex flex-col gap-4">
                <p className="font-semibold">{t('footer.paymentMethods')}</p>
                <div className="flex flex-row  gap-2 md:gap-1 justify-start md:justify-between">
                  <img
                    className="md:h-auto md:w-1/3"
                    src={visaImg}
                    style={{ width: '40px', height: '28px' }}
                    alt="Visa svg"
                  />
                  <img
                    className="md:h-auto md:w-1/3"
                    src={masterCardImg}
                    style={{ width: '40px', height: '28px' }}
                    alt="Mastercard svg"
                  />
                  <img
                    className="md:h-auto md:w-1/3"
                    src={paypalImg}
                    style={{ width: '40px', height: '28px' }}
                    alt="Mastercard svg"
                  />
                </div>
              </div>
              <div className="hidden md:block space-y-4 mb-auto">
                <p className="font-semibold">{t('footer.downloadApp')}</p>
                <div className="flex flex-col w-fit gap-2 justify-start md:justify-between">
                  <a
                    target="_blank"
                    href="https://apps.apple.com/pa/app/gotuuri/id6443990852"
                  >
                    <img
                      className="md:h-10 md:w-full"
                      src={appStoreImg}
                      style={{ width: '130px', height: '40px' }}
                      alt="Appstore svg"
                    />
                  </a>
                  <a
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=com.ap.gotuuri&hl=en_US"
                  >
                    <img
                      className="md:h-10 md:w-full mb-4"
                      src={playStoreImg}
                      style={{ width: '130px', height: '40px' }}
                      alt="Playstore svg"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="block md:hidden space-y-4 mb-auto">
              <p className="font-semibold">{t('footer.downloadApp')}</p>
              <div className="flex flex-col w-fit gap-2 justify-start md:justify-between">
                <a
                  target="_blank"
                  href="https://apps.apple.com/pa/app/gotuuri/id6443990852"
                >
                  <img
                    className="md:h-10 md:w-full"
                    src={appStoreImg}
                    width="130"
                    height="40"
                    alt="Appstore svg"
                  />
                </a>
                <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.ap.gotuuri&hl=en_US"
                >
                  <img
                    className="md:h-10 md:w-full mb-4"
                    src={playStoreImg}
                    width="130"
                    height="40"
                    alt="Playstore svg"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default Footer
