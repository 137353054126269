import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import './phoneCustomInput.css'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

interface PhoneCustomInputProps {
  value: string
  error?: string
  onChange: (newValue) => void
  isEditing: boolean
  height?: string
}
const PhoneCustomInput = ({
  value,
  error,
  onChange = () => {},
  isEditing = true,
  height = 'h-14',
}: PhoneCustomInputProps) => {
  const { t } = useTranslation()

  return (
    <div>
      <PhoneInput
        preferredCountries={['pa', 'co', 'us', 'cr', 've']}
        masks={{ pa: '.... ....' }}
        disabled={!isEditing}
        containerClass={`!flex !flex-row-reverse gap-x-4`}
        inputClass={`!w-full !${height} !border-0 ${
          !!error && 'hover:!bg-redError focus:!bg-redError'
        } !rounded-2xl !pl-6 !text-base ${
          isEditing
            ? 'text-black/90 !bg-mediumGray'
            : 'text-lightGray !bg-white !border-1 !border-gray-200'
        } !ml-auto`}
        buttonClass={`!rounded-2xl !border-0  w-24 flex !justify-center !relative  ${
          isEditing
            ? '!bg-mediumGray'
            : '!bg-white [&>div]:!cursor-default !border-1 !border-gray-200'
        }`}
        dropdownClass={'!left-0 !top-10'}
        countryCodeEditable={!value}
        country={'pa'}
        value={value}
        onChange={(value, country: any, e, formattedValue) =>
          onChange(formattedValue.replaceAll(' ', ''))
        }
        placeholder={t('bookingDetailsPage.phone')}
        inputProps={{ type: 'tel' }}
      />
      {!!error && (
        <p className=" text-rose-500 mt-1 text-xs font-semibold">{error}</p>
      )}
    </div>
  )
}
export default PhoneCustomInput
